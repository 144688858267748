import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

function Footer() {
  return (
    <section className="relative mt-48">
      <div className="w-full bg-secondery py-24 lg:mr-0 lg:ml-auto lg:w-2/3">
        <VarticalHeadWrapperRight className="-mt-48 mb-4 flex">
          <VarticalHead className="font-mincho text-3xl tracking-widest text-primary">
            お問い合わせ
          </VarticalHead>
          <VarticalHeadSupport className="font-mincho text-xs uppercase text-primarySupport">
            Contact
          </VarticalHeadSupport>
        </VarticalHeadWrapperRight>
        <MobileFooter className="lg:ml-24">
          <p className="font-gothic">
            ご相談やお見積りなど、お気軽にお問い合わせください。
          </p>
          <div className="mt-4 font-mincho lg:flex">
            <div className="items-baseline">
              <span className="inline-block">TEL</span>
              <span className="ml-2 inline-block font-mincho text-3xl lg:text-4xl">
                03-5944-0430
              </span>
            </div>
            <div className="items-baseline lg:ml-4">
              <span className="inline-block">FAX</span>
              <span className="ml-2 inline-block text-3xl lg:text-4xl">
                03-5944-0431
              </span>
            </div>
          </div>
          <Link to="/contact">
            <button className="mt-8 rounded border bg-primary py-4 px-24 font-mincho text-white hover:bg-primaryHover lg:mt-16 lg:px-32">
              お問い合わせ
            </button>
          </Link>
        </MobileFooter>
      </div>
      <div className="flex w-full justify-between bg-thirdry py-24 md:pt-24 md:pb-0 lg:mr-0 lg:ml-auto lg:w-2/3">
        <MobileInfoWrapper className="font-mincho text-white lg:ml-24">
          <span className="block">有限会社アートマテリアル</span>
          <span className="mt-4 block">〒160-0004</span>
          <span className="block">
            東京都 新宿区 四谷 三栄町 15-9 寺島ビル3F
          </span>
          <span className="mt-16 block text-xs">Art Material ©</span>
        </MobileInfoWrapper>
        <FooterLogoWrapper className="w-1/3">
          <StaticImage src="../images/footer_logo.png" />
        </FooterLogoWrapper>
      </div>
    </section>
  )
}

const VarticalHead = styled.h2`
  writing-mode: vertical-rl;
`

const VarticalHeadWrapperRight = styled.div`
  margin-left: 80%;
`

const VarticalHeadSupport = styled.span`
  writing-mode: vertical-rl;
`
const MobileFooter = styled.div`
  @media screen and (max-width: 1023px) {
    text-align: center;
  }
`
const MobileInfoWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin-left: 16px;
  }
`
const FooterLogoWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`

export default Footer
