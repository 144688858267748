import Logo from "../images/logo.svg"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div className="fixed z-10 mt-8 w-full font-mincho text-base lg:ml-12 lg:h-screen lg:w-3/12 lg:max-w-sm 2xl:ml-20">
      <header className="mx-auto lg:mt-12">
        <div className="flex w-full items-center justify-between">
          <Link to="/">
            <img
              src={Logo}
              alt="アートマテリアル"
              className="ml-4 h-12 lg:h-20"
            />
          </Link>
          <div className="z-50 mr-4">
            <button
              className={`${
                isExpanded ? `hidden ` : `block`
              } text-xl focus:outline-none lg:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
              aria-label="メニュー"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <button
              className={`${
                isExpanded ? `block` : `hidden`
              } text-xl text-white focus:outline-none lg:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
              aria-label="メニュー"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
        <MobileNav
          className={`${
            isExpanded ? `block` : `hidden`
          } text-main fixed left-0 z-10 h-screen w-full text-center lg:static lg:mt-16 lg:block lg:text-left`}
        >
          <MobileNavItem>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/profile">アートマテリアルについて</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/products">製品紹介</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/facility">設備紹介</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/works">納入実績</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/construct">施工について</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/faq">よくある質問</Link>
            </li>
            <li className="m-2 block px-4 py-2 hover:text-gray-500">
              <Link to="/company">会社概要</Link>
            </li>
            <li className="mx-4 mt-8">
              <Link to="/contact">
                <button className="w-48 rounded bg-primary px-4 py-3 text-white hover:bg-primaryHover">
                  お問い合わせ
                </button>
              </Link>
            </li>
          </MobileNavItem>
        </MobileNav>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const MobileNav = styled.div`
  @media screen and (max-width: 1023px) {
    background-color: rgba(51, 51, 51, 0.95);
    inset: 0;
    color: white;
  }
`
const MobileNavItem = styled.ul`
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

export default Header
